import React from 'react'
import strings from '../language/strings';
import CancelButton from './CancelButton';

class ShareResults extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.reveal_sharer);
    }

    restart = e => {

        e.preventDefault();
    
        this.props.restartFresh();
    
    }

    invite = e => {

        e.preventDefault();
    
        window.location.hash = 'resultsmsshare';

        console.log('resultsmsshare');
    
    }
    
    render(){

       return(
            <div className={this.props.reveal_sharer ? 'share-results share-results--visible' : 'share-results'}>
                <h2>{strings.happy_title}</h2>

                <p className="large-text"><strong>{strings.happy_helpus}</strong></p>

                <p>{strings.happy_invite}</p>

                <button className="button button--submit button--invite button--marginbottom" onClick={ this.invite }>{strings.happy_sms}</button>

                <CancelButton url={ this.props.return_url } />


            </div>
        )


    }
   
}

export default ShareResults