const hairfinder = {
    get_haircuts
};

async function get_haircuts( args ){

    console.log( args );
    
    let json_file = false;

    if( args.tag_gender === 'MAN' ){

        if( args.tag_age >= 55 ){
            json_file = 'man55plus';
        }
        else{
            json_file = 'man';
        }

    }
    else if( args.tag_gender === 'WOMAN' ){

        if( args.tag_age >= 55 ){
            json_file = 'woman55plus';
        }
        else{
            json_file = 'woman';
        }

    }
    else{
        return false;
    }

    json_file += '_';
    json_file += args.tag_faceshape.toLowerCase();
    json_file += '.json';

    console.log(json_file);

    const json_data = await load_json_data( json_file );

    if( json_data ){

        let search_string = args.tag_length + ' LENGTH, ' + args.tag_look + ', ' + args.tag_styling + ' STYLING';

        let row = json_data.find(row => row.tags === search_string);

        if( row ){

            let image1 = '';
            let image2 = '';
            let image3 = '';

            if( row.hasOwnProperty('image1') ){
                image1 = row.image1;
            }

            if( row.hasOwnProperty('image2') ){
                image2 = row.image2;
            }

            if( row.hasOwnProperty('image3') ){
                image3 = row.image3;
            }

            let response = {
                tags: row.tags,
                json_file: json_file,
                images: [image1, image2, image3]
            }

            return response;
        }
        else{
            return false;
        }
        
    }
    else{
        return false;
    }


}

const load_json_data = async ( file ) => {

    const response = await fetch( '/json/' + file );

    const data = await response.json();

    return data;

};

export default hairfinder;