import React from 'react';
import CancelLink from './CancelLink';
import strings from '../language/strings';

class Step1Gender extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectedButton: '' }
    }

    setGender( e, gender ){
        e.preventDefault();
        this.setState( { 'selectedButton': gender } );

        //change state in parent
        this.props.handleChange( 'tag_gender', gender );
       
    }

    Continue = e => {
        e.preventDefault();

        console.log(this.state);
    
    
        if( !this.props.getParentState('tag_gender').length ){
            alert(strings.please_select);
        }
        else{
           this.props.goToStep( 'step1b' );
        }


    }
    
    render(){
       //const {goToStep, handleChange, values } = this;

       return(
        <div className="app-inner">

            <div className="app-top">

                <CancelLink url={ this.props.return_url} />
            
                <h2>{strings.gender_instructions}</h2>

                <p>{strings.gender_pleasechoose}</p>

                <form className="option-form">

                    <button 
                    className={'WOMAN' === this.state.selectedButton ? 'selected' : ''}
                    onClick={ e => this.setGender(e, 'WOMAN') }
                    >
                    {strings.gender_woman}
                    </button>

                    <button 
                    className={'MAN' === this.state.selectedButton ? 'selected' : ''}
                    onClick={ e => this.setGender(e, 'MAN') }
                    >
                    {strings.gender_man}
                    </button>

                </form>

            </div>
                <div className="app-bottom">

                    <button className="button button--submit" onClick={ this.Continue }>{strings.continue}</button>
                </div>
            </div>
        )


    }
   
}

export default Step1Gender