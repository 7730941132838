import React from 'react'
import strings from '../language/strings';
import CancelLink from './CancelLink';

class Error extends React.Component {


    restart = e => {
        e.preventDefault();
    
        this.props.restartFresh();
    
    }
    
    render(){


       return(
        <div className="app-inner">

            <div className="app-top">

                <CancelLink url={ this.props.return_url} />

                <h2>{strings.error_1}</h2>
                <h2>{strings.error_2}</h2>
            </div>
            <div className="app-bottom">
                <button className="button button--submit" onClick={ this.restart }>{strings.start_over}</button>
            </div>

 
        </div>
        )


    }
   
}

export default Error