

import React from 'react'

import Start from './Start';
import Step1Selfie from './Step1Selfie';
import Step1Shape from './Step1Shape';
import Step1Gender from './Step1Gender';
import Step2Length from './Step2Length';
import Step3Look from './Step3Look';
import Step4Styling from './Step4Styling';
import Fail from './Fail';
import Results from './Results';
import Error from './Error';
import Feedbackform from './Feedbackform';


import { return_url_man, return_url_woman } from "../utils/constants";


export default class MfGuide extends React.Component {

    state = {
        step: 'start',
        image: '',
        betaface_response: '',
        betaface_headshape: '',
        betaface_chinsize: '',
        betaface_headwidth: '',
        betaface_haircolor: '',
        betaface_hairlength: '',
        betaface_gender: '',
        algo_faceshape: '',
        tag_gender: '',
        tag_race: '',
        tag_age: '',
        tag_faceshape: '',
        tag_length: '',
        tag_look: '',
        tag_styling: '',
        haircut_suggestions: '',
        params_checked: false,
        param_gender: '',
        clientID: false,
        return_url: '',
        happy : '',
        happy_image : '',
        current_image : 0,
        log_timestamp : '',
        log_image_key : '',
    }

    restartFresh = () => {

      this.setState({
        image: '',
        betaface_response: '',
        betaface_headshape: '',
        betaface_chinsize: '',
        betaface_headwidth: '',
        betaface_haircolor: '',
        betaface_hairlength: '',
        betaface_gender: '',
        algo_faceshape: '',
        tag_gender: '',
        tag_race: '',
        tag_age: '',
        tag_faceshape: '',
        tag_length: '',
        tag_look: '',
        tag_styling: '',
        haircut_suggestions: '',
        happy : '',
        happy_image : '',
        current_image : 0,
        log_timestamp : '',
        log_image_key : '',
      });

      this.goToStep('start');

    }


    goToStep = ( new_step ) => {
        this.setState( { step: new_step } );
        window.location.hash = new_step;
    }

    handleChange = (property, value) => {
        this.setState( { [property] : value } );
    }

    getParentState = (property) => {
        return this.state[property];
    }

    getParentFullState = () => {
        return this.state;
    }

    componentDidMount(){

      

      if( !this.state.params_checked ){
          this.storeParams();
      }

      // set cross browser compatible "vh"
      this.setViewportHeight();
      window.addEventListener("resize", this.setViewportHeight);


    }
    
    storeParams(){
       // store url params in state
       const queryParams = new URLSearchParams(window.location.search);
       const clientID = queryParams.get('clientID');
       const gender = queryParams.get('gender');
 
       //console.log(clientID);
       
       if( clientID ){
         this.setState({ 
            clientID : clientID,
            params_checked: true 
          });

       }

       if( gender ){

        if( gender === 'woman' ){
          this.setState({ return_url : return_url_woman });
          this.setState({ param_gender : 'WOMAN' });
        }
        else{
          this.setState({ return_url : return_url_man });
          this.setState({ param_gender : 'MAN' });
        }

       }

       window.history.pushState(null, "", window.location.href.split("?")[0]);

    }

    setViewportHeight(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    render(){

        const { step } = this.state;

        console.log('parent:');
        console.log(this.state);

        switch(step) {
            case 'start': 
              return (
                <Start 
                  goToStep = { this.goToStep }
                  return_url = { this.state.return_url }
                  getParentState = {this.getParentState} 
                />
              )
            case 'step1': 
              return (
                <Step1Selfie 
                  goToStep = { this.goToStep }  
                  handleChange = { this.handleChange }
                  getParentState = {this.getParentState}
                  return_url = { this.state.return_url }
                />
              )
            case 'step1b': 
              return (
                <div>
                  <Step1Shape 
                    goToStep={ this.goToStep }  
                    handleChange={ this.handleChange }
                    getParentState= {this.getParentState}
                    return_url = { this.state.return_url }
                  />
                  
                </div>

              )
            case 'step1g': 
              return (
                <div>
                  <Step1Gender
                    goToStep={ this.goToStep }  
                    handleChange={ this.handleChange }
                    getParentState= {this.getParentState}
                    return_url = { this.state.return_url }
                  />
                  
                </div>

              )
            case 'step2': 
                return (
                  <div>
                    <Step2Length
                      goToStep={ this.goToStep }
                      handleChange={ this.handleChange }
                      getParentState= {this.getParentState} 
                      returnUrl={ this.state.return_url }
                      return_url = { this.state.return_url }
                    />
                  </div>
                )
            case 'step3': 
                return (
                  <Step3Look
                    goToStep = { this.goToStep }
                    handleChange = { this.handleChange }
                    getParentState = {this.getParentState} 
                    return_url = { this.state.return_url }
                  />
                )
            case 'step4': 
                return (
                  <Step4Styling
                    goToStep = { this.goToStep }
                    handleChange = { this.handleChange }
                    getParentState = {this.getParentState} 
                    return_url = { this.state.return_url }
                  />
                )
             case 'fail': 
                return (
                    <div>
                      <Fail
                      goToStep = { this.goToStep } 
                      handleChange ={ this.handleChange }
                      restartFresh = { this.restartFresh }
                      getParentState = {this.getParentState}
                      return_url = { this.state.return_url }
                      />
                    </div>
                )
              case 'error': 
                return (
                    <Error
                    goToStep = { this.goToStep } 
                    handleChange ={ this.handleChange }
                    restartFresh = { this.restartFresh }
                    getParentState = {this.getParentState}
                    return_url = { this.state.return_url }
                    />
                )
            case 'results': 
                return (
                    <Results
                    goToStep = { this.goToStep } 
                    handleChange = { this.handleChange }
                    getParentState = {this.getParentState}
                    restartFresh = { this.restartFresh }
                    getParentFullState = {this.getParentFullState}
                    return_url = { this.state.return_url }
                    />
                )
              case 'feedbackform': 
                return (
                  <Feedbackform
                  goToStep = { this.goToStep } 
                  handleChange = { this.handleChange }
                  getParentState = {this.getParentState}
                  restartFresh = { this.restartFresh }
                  getParentFullState = {this.getParentFullState}
                  return_url = { this.state.return_url }
                  />
                )
              
            default: 
                return (
                    <Start 
                    goToStep = { this.goToStep }  
                    getParentState = {this.getParentState}
                    />
                )
          }
        


    }
}