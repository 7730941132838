import React from 'react';
import CancelLink from './CancelLink';
import strings from '../language/strings';

class Step3Look extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectedButton: '' }
    }

    setLook( e, look ){
        e.preventDefault();
        this.setState( { 'selectedButton': look } );

        //change state in parent
        this.props.handleChange( 'tag_look', look );
       
    }

    Continue = e => {
        e.preventDefault();

        console.log(this.state);
    
    
        if( !this.props.getParentState('tag_look').length ){
            alert(strings.please_select);
        }
        else{
           this.props.goToStep( 'step4' );
        }


    }
    
    render(){
       //const {goToStep, handleChange, values } = this;

       return(
        <div className="app-inner">

            <div className="app-top">

                <CancelLink url={ this.props.return_url} />
            
                <h2>{strings.look_instructions}</h2>

                <form className="option-form">

                    <button 
                    className={'PROFESSIONAL' === this.state.selectedButton ? 'selected' : ''}
                    onClick={ e => this.setLook(e, 'PROFESSIONAL') }
                    >
                    {strings.look_professional}
                    </button>

                    <button 
                    className={'TRENDY' === this.state.selectedButton ? 'selected' : ''}
                    onClick={ e => this.setLook(e, 'TRENDY') }
                    >
                    {strings.look_trendy}
                    </button>

                    <button 
                    className={'DIFFERENT' === this.state.selectedButton ? 'selected' : ''}
                    onClick={ e => this.setLook(e, 'DIFFERENT') }
                    >
                    {strings.look_different}
                    </button>

                    <button 
                    className={'YOUNGER' === this.state.selectedButton ? 'selected' : ''}
                    onClick={ e => this.setLook(e, 'YOUNGER') }
                    >
                    {strings.look_younger}
                    </button>



                </form>

            </div>
                <div className="app-bottom">

                    <button className="button button--submit" onClick={ this.Continue }>{strings.continue}</button>
                </div>
            </div>
        )


    }
   
}

export default Step3Look