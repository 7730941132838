import React from 'react'
import hairfinder from "../functions/hairfinder"
import Confetti from 'react-confetti'
import ShareResults from './ShareResults'
import Loader from './Loader'
import CancelLink from './CancelLink';

import { LOCAL_API_BASE } from "../utils/constants";
import strings from '../language/strings'

class Results extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            suggestions: false, 
            current_image : 0,
            current_pinterest_url : '',
            reveal_sharer: false,
            first_try: true,
            loading: true,  
        }
    }

    componentDidMount(){

        let args = {
            tag_gender : this.props.getParentState('tag_gender'),
            tag_race : this.props.getParentState('tag_race'),
            tag_age : this.props.getParentState('tag_age'),
            tag_faceshape : this.props.getParentState('tag_faceshape'),
            tag_length : this.props.getParentState('tag_length'),
            tag_look : this.props.getParentState('tag_look'),
            tag_styling : this.props.getParentState('tag_styling')
        };

        hairfinder.get_haircuts( args ).then((data) => {

            const that = this;

            if( data ){

                setTimeout(function(){

                    that.setState( { suggestions: data } );
                    that.setState( { loading: false } );
                    that.props.handleChange( 'haircut_suggestions', data );
                    //this.setimage(0);
                    window.parsePinBtns();

                }, 2500);

               
            }

            else{
                this.props.goToStep( 'error' );
            }

        });
        

    }

    nextImage = e => {

        e.preventDefault();

        console.log('next..');

        let next_image = parseInt( this.state.current_image + 1 );

        const that = this;

        this.setState( { first_try: false } );
        this.setState( { loading: true } );

        setTimeout(function(){

            that.setState( { loading: false } );

            if( that.state.current_image >= 2 ){
                that.notHappy();
            }
            else{
                
                that.setState( { current_image : next_image } );
                that.props.handleChange( 'current_image', next_image);
                window.parsePinBtns();
            }

        }, 2500);

       
    
    
    }

    restart = e => {
        e.preventDefault();
    
        this.props.goToStep( 'start' );
    
    }

    happy = e => {

        e.preventDefault();

        let happy_image = this.state.suggestions.images[this.state.current_image];


        this.props.handleChange( 'happy', true );
        this.props.handleChange( 'happy_image', happy_image );


        this.setState({ reveal_sharer: true});

        this.createLog( true, happy_image );

        
        
    }

    notHappy(){
        this.createLog( false );
        this.props.goToStep( 'feedbackform' );
    }

    createLog( happy, happy_image = '' ){

        console.log('create log');

        const api_url = LOCAL_API_BASE + '/log.php';

        let data = this.props.getParentFullState();
        data.happy = happy;
        data.happy_image = happy_image;

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( data ),
        };

        fetch(api_url, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            this.props.handleChange( 'log_timestamp', data["timestamp"] );
            this.props.handleChange( 'log_image_key', data["image_key"] );

        })
        .catch((error) => {
            console.error('Error:', error);

            // maybe not render error if log fails.. just fail silently
            // this.props.goToStep( 'error' );
    
        });

    }
    
    render(){

       if( this.state.loading ){
           return(
            <div className="app-inner">
                <div className="app-top">

                    <CancelLink url={ this.props.return_url} />

                    {!this.state.first_try && <h2>{strings.results_newtry}</h2>}

                    <h2>{strings.results_loading}</h2>
                </div>
                <div className="app-loader">
                    <Loader />
                </div>
            </div>
           )
       }


       else if( this.state.suggestions ){

            const { reveal_sharer } = this.state;

            const confetti_colors = ['#000000','#ffffff'];


            return(
                <div className="app-inner app-inner--results">

                    <CancelLink url={ this.props.return_url} />

                    <ShareResults
                        goToStep={ this.props.goToStep }  
                        restartFresh = { this.props.restartFresh } 
                        reveal_sharer={this.state.reveal_sharer}
                        return_url = { this.props.return_url }
                    />

                    <h2>{strings.results_matching}</h2>

                    <div className={0 === this.state.current_image ? 'pin-visible' : 'pin-invisible'}>
                        <a data-pin-do="embedPin" data-pin-width="medium" href={this.state.suggestions.images[0]}></a>
                    </div>
                    
                    <div className={1 === this.state.current_image ? 'pin-visible' : 'pin-invisible'}>
                        <a data-pin-do="embedPin" data-pin-width="medium" href={this.state.suggestions.images[1]}></a>
                    </div>

                    <div className={2 === this.state.current_image ? 'pin-visible' : 'pin-invisible'}>
                        <a data-pin-do="embedPin" data-pin-width="medium" href={this.state.suggestions.images[2]}></a>
                    </div>


                    <Confetti recycle={false} numberOfPieces="200" colors={confetti_colors} />

                    <div className={reveal_sharer ? 'results-cta results-cta--hidden' : 'results-cta'}>
                        <label>{strings.results_happyquestion}</label>
                        <div className="results-cta__buttons">
                            <button className="button-yes" onClick={ this.happy }>{strings.yes} 👍</button>
                            <button className="button-no" onClick={ this.nextImage }>{strings.no} 👎</button>
                        </div>
                    </div>


                </div>

                

            )
            
       }
       else{
            return(
                <div>Processing...</div>
            ) 
       }
       
      


    }
   
}

export default Results