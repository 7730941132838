import React from 'react';
import CancelLink from './CancelLink';
import CancelButton from './CancelButton';
import validator from 'validator';
import Loader from './Loader';
import { LOCAL_API_BASE } from "../utils/constants";
import strings from '../language/strings';

class Feedbackform extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step : 'message', 
            message : '', 
            email : '',
            error_message : false,
            error_email : false,
            loading: false,

        }

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {

        const target = event.target;

        var value = target.value;

        const name = target.name;

        this.setState({

            [name]: value

        });
        
        
        

    }


    submit_message = e => {

       if( this.state.message && this.state.message.length > 3){
           this.setState({ step : 'email' });
       }
       else{
           this.setState( { error_message : true } );
       }

    }

    submit_form = e => {

        if (validator.isEmail( this.state.email ) ) {
            
            this.send_data();
        }
        else{
            this.setState( { error_email : true } );
        }
 
    }

    send_data(){

        this.setState({ loading : true });

        const api_url = LOCAL_API_BASE + '/feedback.php';

        let data = this.props.getParentFullState();
        data.image = ''; // we don't need to send this large chunk..
        data.feedback_email = this.state.email;
        data.feedback_message = this.state.message;

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( data ),
        };

        fetch(api_url, requestOptions)
        .then(response => response.json())
        .then(data => {
            
            if( data.status ){
                this.setState({ step : 'thankyou', loading : false });
            }

          

        })
        .catch((error) => {
            console.error('Error:', error);

            this.props.goToStep( 'error' );
    
        });

    }

    restart = e => {

        e.preventDefault();
    
        this.props.restartFresh();
    
    }
    
    render(){

        if( this.state.loading ){
            return(
             <div className="app-inner">
                    <Loader />
             </div>
            )
        }

        else if( this.state.step === 'message' ){
            return( 

                <div className="feedback-form">

                    <CancelLink url={ this.props.return_url} />

                    <h2>{strings.sad_title}</h2>

                    <h2>👉 {strings.sad_feedbacktitle}</h2>

                    <p>
                        {strings.feedback_messagelabel}
                    </p>


                    <textarea name="message" className={this.state.error_message ? 'error' : ''}  onChange={this.handleInputChange}></textarea><br />

                    <button className="button button--submit" onClick={ this.submit_message}>{strings.submit}</button>
                    
                    
                    { this.props.return_url && (
                       <div className="submit-form__label">{strings.or}</div>
                    )}

                    <CancelButton url={ this.props.return_url} />

                       
        
                </div>
            )

        }

        else if( this.state.step === 'email' ){
            return( 

                <div className="feedback-form">

                    <CancelLink url={ this.props.return_url} />

                    <h2>{strings.feedback_onemorething}</h2>

                    <p>
                        {strings.feedback_email}
                    </p>


                    <input name="email" type="email" className={this.state.error_email ? 'error' : ''}  onChange={this.handleInputChange} /><br />

                    <button className="button button--submit" onClick={ this.submit_form }>{strings.submit}</button><br />
                    
        
                </div>
            )

        }

        else if( this.state.step === 'thankyou' ){
            return( 

                <div className="feedback-form">

                    <CancelLink url={ this.props.return_url} />

                    <h2>{strings.thankyou}</h2>

                    <p>
                        {strings.feedback_response}
                    </p>


                    <CancelButton url={ this.props.return_url} />
                    
        
                </div>
            )

        }


    }
   
}

export default Feedbackform