import React from 'react'
import Webcam from 'react-webcam';
import WebcamOverlay from '../assets/img/exclude.svg';
import { css } from "@emotion/react";
import GridLoader from "react-spinners/GridLoader";

import strings from '../language/strings';

import tut2 from '../assets/img/selfie-tutorial-2.svg';
import { ReactComponent as Noglasses } from '../assets/img/selfie-tutorial-2.svg';


function WebcamCapture(props) {
    const webcamRef = React.useRef(null);
    const noglasses = tut2;
    const [imgSrc, setImgSrc] = React.useState(null);
    const [showWebcam, setShowWebcam] = React.useState(true);
    const [showLoader, setShowLoader] = React.useState(false);
    const {handleImage} = props;

    console.log(noglasses);

    const videoConstraints = {
      //width: { min: 480 },
      //height: { min: 720 },
      // i have no idea why this needs to be flipped in mobile safari to get portrait video, but it does.
      width: { min: 720 },
      height: { min: 480 },
      //aspectRatio: 0.6666666667
    };
    
  
    
    const capture = React.useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      
      if( !imageSrc ){
          alert(strings.webcam_pleasewait);
          return;
      }

      setShowWebcam(false);
      setImgSrc(imageSrc);
      setShowLoader(true);
      handleImage(imageSrc);
    }, [webcamRef, setImgSrc]);

      const loader_css = css`
          display: block;
          margin: 0 auto;
      `;

      let loader_color = "#ffffff";
  
    return (
      <>
       {showWebcam && <Webcam
          audio={false}
          videoConstraints={videoConstraints} 
          width={480}
          mirrored={true} 
          height={720}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style = {{width: "100%", height: "100%", position: "absolute", left: "50%", marginLeft: "-50%", objectFit: "cover", objectPosition: "center"}}
        />}

        {imgSrc && (
          <img
            alt="Capture"
            src={imgSrc}
            style = {{width: "100%", height: "100%", position: "absolute", left: "50%", marginLeft: "-50%", objectFit: "cover", objectPosition: "center"}}
            onChange={props.handleImage}
          />
        )}

         {showLoader && (
          <div className="webcam-loader">
            <GridLoader color={loader_color} loading={true} css={loader_css} size={10} margin={18} />
          </div>
        )}    
        
        {showWebcam && <div className="webcam-overlay"><div className="webcam-overlay-mask"><img src={WebcamOverlay} alt="Overlay" width="375" height="441" /></div></div>}
        {showWebcam && <div className="webcam-overlay-noglasses"><Noglasses width="34" height="34" /></div>}
        {showWebcam && <div className="webcam-capture"><button className="button button--submit button--capture" onClick={capture}>{strings.webcam_capburebutton}</button></div>}
        
        
      </>
    );
  };

  export default WebcamCapture