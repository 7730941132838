import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
 en:{
   cancel: 'Cancel',
   cancelbutton: 'Back to home screen',
   continue: 'Continue',
   yes: 'Yes',
   no: 'No',
   start_over: 'Start over',
   submit: 'Submit',
   or: 'or',
   show_history: 'Your saved haircuts',
   thankyou : 'Thank you!',
   please_select: 'Please select',
   start_instructions_1:'Take a selfie straight from the front',
   start_instructions_2:'Take off your glasses',
   start_instructions_3:'Make sure your hair does not hide your face',
   start_intro: 'Our unique algorithm is continiously improved in collaboration with best Scandinavian hair stylists.',
   start_startbutton: 'Ok, start!',
   webcam_topbar: 'Take a frontal selfie',
   webcam_pleasewait: 'Please allow webcam to load',
   webcam_capburebutton: 'Capture photo',
   gender_instructions: 'The image quality makes it difficult to decide whether we should show proposals for women or men.',
   gender_pleasechoose: 'Please choose:',
   gender_man: 'Man',
   gender_woman: 'Woman',
   shape_hint: 'According to our algorhitm your face shape is:',
   OVAL: 'OVAL',
   SQUARE: 'SQUARE',
   TRIANGLE: 'TRIANGLE',
   ROUND: 'ROUND',
   shape_instructions: 'Please confirm or select a shape that fits better:',
   length_instructions: 'Which hairstyle length do you prefer?',
   length_short: 'Short',
   length_medium: 'Medium',
   length_long: 'Long',
   look_instructions: 'My new hairstyle should above all make me look:',
   look_trendy: 'Trendy',
   look_professional: 'Professional',
   look_different: 'Different',
   look_younger: 'Younger',
   styling_instructions: 'How much time are you willing to spend on styling at home?',
   styling_min: 'Min',
   styling_med: 'Medium - some styling',
   styling_max: 'Max - I love styling 😛',
   results_loading: 'Searching for matching hairstyles. Hang on!',
   results_newtry: "Ok, let's try again",
   results_happyquestion: 'Are you happy with this suggestion?',
   results_matching: 'Matching hairstyle',
   happy_title: 'Glad you like the hairstyle!',
   happy_helpus: 'Help us! We need more users to improve the algorithm.',
   happy_invite: 'Invite Friends & Family and ask them to test.',
   happy_sms: 'Invite via SMS (free)',
   sad_title: 'Unfortunately the algorithm did not find a suggestion for you...',
   sad_feedbacktitle: 'But our hair stylists are here to help you!',
   feedback_messagelabel: 'Tell us what are you looking for:',
   feedback_onemorething: 'One more thing...',
   feedback_email: 'Enter your e-mail:',
   feedback_response: 'Feedback response',
   fail_1: '🙁 Sorry, the image quality is not enough to find a suggestion.',
   fail_2: 'The algorithm is continiously improved – please try later.',
   error_1: '🙁 We are sorry, we have encountered an error in the app.',
   error_2: 'Please try again or contact us with details about how this happened.'
 },
 sv: {
   cancel: 'Avbryt',
   cancelbutton: 'Gå till hemskärmen',
   continue: 'Fortsätt',
   yes: 'Ja',
   no: 'Nej',
   start_over: 'Börja om',
   submit: 'Skicka',
   or: 'eller',
   thankyou : 'Tack!',
   show_history: 'Dina sparade frisyrer',
   please_select: 'Vänligen välj',
   start_instructions_1:'Ta en selfie rakt framifrån',
   start_instructions_2:'Ta av glasögon',
   start_instructions_3:'Se till att håret inte döljer ditt ansikte',
   start_intro: 'Vår unika algoritm förbättras löpande i samarbete med några av Skandinaviens bästa frisörer och hårstylister.',
   start_startbutton: 'Ok, kör igång!',
   webcam_topbar: 'Ta en selfie rakt framifrån',
   webcam_pleasewait: 'Vänligen vänta tills webbkameran har laddats',
   webcam_capburebutton: 'Ta bild',
   gender_instructions: 'Bildkvaliten gör det svårt att avgöra om vi ska visa förslag för kvinnor eller män.',
   gender_pleasechoose: 'Välj nedan:',
   gender_man: 'Man',
   gender_woman: 'Kvinna',
   shape_hint: 'Enligt vår algoritm är din ansiktsform:',
   OVAL: 'OVALT',
   SQUARE: 'FYRKANTIGT',
   TRIANGLE: 'TREKANTIGT',
   ROUND: 'RUNT',
   shape_instructions: 'Bekräfta eller välj en ansiktsform som du tycker passar bättre:',
   length_instructions: 'Vilken längd vill du ha på din nya frisyr?',
   length_short: 'Kort',
   length_medium: 'Mellanlångt',
   length_long: 'Långt',
   look_instructions: 'Min nya frisyr ska framförallt göra att jag uppfattas som:',
   look_trendy: 'Trendig',
   look_professional: 'Professionell',
   look_different: 'Annorlunda',
   look_younger: 'Yngre',
   styling_instructions: 'Hur mycket tid vill du lägga på styling hemma?',
   styling_min: 'Minimalt = lättskött',
   styling_med: 'Medium = lite styling är ok',
   styling_max: 'Mycket = jag älskar styling 😛',
   results_loading: 'Letar efter matchande frisyrer. Häng kvar!',
   results_newtry: 'Ok, vi gör ett nytt försök!',
   results_happyquestion: 'Är du nöjd med förslaget?',
   results_matching: 'Matchande frisyr',
   happy_title: 'Kul att du gillar förslaget!',
   happy_helpus: 'Hjälp oss! Vi behöver fler användare för att kunna förbättra algoritmen.',
   happy_invite: 'Bjud in Vänner & Familj och be dem testa.',
   happy_sms: 'Bjud in via SMS (gratis)',
   sad_title: 'Trist att algoritmen inte hittade en frisyr till dig...',
   sad_feedbacktitle: 'Men våra hårstylister är redo att hjälpa dig!',
   feedback_messagelabel: 'Berätta vad du är ute efter:',
   feedback_onemorething: 'En sak till...',
   feedback_email: 'Fyll i din e-postadress:',
   feedback_response: 'Vi återkommer till dig inom kort.',
   fail_1: '🙁 Tyvärr, bilden är inte tillräckligt bra för att ge ett bra förslag.',
   fail_2: 'Algoritmen utvecklas kontinuerligt - prova gärna vid senare tillfälle.',
   error_1: '🙁 Sorry, det har blivit något fel i appen.',
   error_2: 'Prova gärna igen eller kontakta oss med info om hur detta hände.'

 }
});

export default strings;