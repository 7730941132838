import React from 'react'
import strings from '../language/strings';

class CancelButton extends React.Component {

    render(){

       if( this.props.url && this.props.url !== '' ){
            return(
                
                <a className="button button--black" href={this.props.url}>{ strings.cancelbutton }</a>
              
            
            )
       }
       else{
           return '';
       }

    }
   
}

export default CancelButton