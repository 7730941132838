import React from 'react'
import strings from '../language/strings';

class CancelLink extends React.Component {

    render(){

       if( this.props.url && this.props.url !== '' ){
            return(
                <div className="cancel-content">
                    <a href={this.props.url}>{ strings.cancel }</a>
                </div>
            
            )
       }
       else{
           return '';
       }

    }
   
}

export default CancelLink