import React from 'react';
import CancelLink from './CancelLink';
import strings from '../language/strings';

class Step4Styling extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectedButton: '' }
    }

    setStyling( e, styling ){
        e.preventDefault();
        this.setState( { 'selectedButton': styling } );

        //change state in parent
        this.props.handleChange( 'tag_styling', styling );
       
    }

    Continue = e => {
        e.preventDefault();

        console.log(this.state);
    
    
        if( !this.props.getParentState('tag_styling').length ){
            alert(strings.please_select);
        }
        else{
           this.props.goToStep( 'results' );
        }


    }
    
    render(){

       return(
            <div className="app-inner">
                <div className="app-top">

                    <CancelLink url={ this.props.return_url} />

                    <h2>{strings.styling_instructions}</h2>

                    <form className="option-form">

                        <button 
                        className={'MIN' === this.state.selectedButton ? 'selected' : ''}
                        onClick={ e => this.setStyling(e, 'MIN') }
                        >
                        {strings.styling_min}
                        </button>

                        <button 
                        className={'MEDIUM' === this.state.selectedButton ? 'selected' : ''}
                        onClick={ e => this.setStyling(e, 'MEDIUM') }
                        >
                        {strings.styling_med}
                        </button>

                        <button 
                        className={'MAX' === this.state.selectedButton ? 'selected' : ''}
                        onClick={ e => this.setStyling(e, 'MAX') }
                        >
                        {strings.styling_max}
                        </button>

                    </form>
                </div>
                
                <div className="app-bottom">
                    <button className="button button--submit" onClick={ this.Continue }>{strings.continue}</button>
                </div>
            </div>
        )


    }
   
}

export default Step4Styling