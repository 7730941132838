import React from 'react'
import CancelLink from './CancelLink';
import strings from '../language/strings';

class Step2length extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectedButton: '' }
    }

    setLength( e, length ){
        e.preventDefault();
        this.setState( { 'selectedButton': length } );

        //change state in parent
        this.props.handleChange( 'tag_length', length );
       
    }

    Continue = e => {
        e.preventDefault();

        console.log(this.state);
    
    
        if( !this.props.getParentState('tag_length').length ){
            alert(strings.please_select);
        }
        else{
        
            // skip step 3 look for 55+
            if( this.props.getParentState('tag_age') >= 55 ){
                this.props.goToStep( 'step4' );
            }
            else{
                this.props.goToStep( 'step3' );
            }

           
        }


    }
    
    render(){

       return(
            <div className="app-inner">
                    
                <div className="app-top">

                    <CancelLink url={ this.props.return_url} />

                    <h2>{strings.length_instructions}</h2>

                    <form className="option-form">

                        <button 
                        className={'SHORT' === this.state.selectedButton ? 'selected' : ''}
                        onClick={ e => this.setLength(e, 'SHORT') }
                        >
                        {strings.length_short}
                        </button>

                        <button
                        className={'MEDIUM' === this.state.selectedButton ? 'selected' : ''}
                        onClick={ e => this.setLength(e, 'MEDIUM') }
                        >
                        {strings.length_medium}
                        </button>

                        <button
                        className={'LONG' === this.state.selectedButton ? 'selected' : ''}
                        onClick={ e => this.setLength(e, 'LONG') }
                        >
                        {strings.length_long}
                        </button>

                    </form>
                </div>

                <div className="app-bottom">

                    <button className="button button--submit" onClick={ this.Continue }>{strings.continue}</button>

                </div>
            </div>
        )


    }
   
}

export default Step2length